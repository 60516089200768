<template>
  <div class="mine-container">
    <div class="mine-top">
      <div class="flex-start">
        <div class="header" :class="`header-${avatar}`" @click="toPath('header')"></div>
        <div class="flex-1">
          <div class="flex-center-start">
            <div class="name">{{nickName}}</div>
            <div class="tag">{{getLevelName()}}</div>
          </div>
          <div class="flex-center-start level">
            {{$t('mine.label5')}}：
            <img :src="levelUrl" alt="">
          </div>
          <div class="phone">
            <Icon name="phone" color="#fff"></Icon>: {{userName}}
          </div>
        </div>
        <div class="lang-icon mine" @click="toLang">
          <div class="lang-img">
            <img :src="langIcon" alt="" >
          </div>
          <div class="lang-list" v-show="showLang">
            <div class="list" v-for="(item, i) in langList" :class="item.key == lang ? 'active' : ''" :key="i" @click.stop="changeLang(item)">
               <img :src="item.icon" alt="" >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mine-main">
      <div class="money-box">
        <div class="flex-center-between">
          <div>
            <div class="label">{{i18n.t('mine.label2')}}</div>
            <div class="money">$ {{parseFloat(userMoney).toFixed(2)}}</div>
          </div>
          <div class="money-btn ">
            <div class="btn " @click="toPath('recharge')">
              {{i18n.t('mine.label3')}}
            </div>
            <div class="btn blue" @click="toPath('withdraw')">
              {{i18n.t('mine.label4')}}
            </div>
          </div>
        </div>

      </div>

      <div class="menu-list flex-wrap">
        <div class="list flex-center-between" :class="`bg${i + 1}`" v-for="(item, i) in menuList" :key="i" @click="toPath(item.path)">
          <div class="text " >{{item.text}}</div>
          <div class="more">
            <Icon name="arrow" color="#fff" size="14"></Icon>
          </div>
        </div>
        <div class="logout">
          <Button @click="logout">{{i18n.t('mine.btn')}}</Button>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { userInfo } from '@/api/user'
import { Icon, Button, Popup } from 'vant'
import { removeToken } from '@/utils/auth'
import Clipboard from 'clipboard';
export default {
  name: 'mine',
  components: {
    Icon,
    Popup,
    Button
  },
  data() {
    return {
      header: 1,
      userName: '',
      nickName: '',
      userMoney: '0.00',
      userPhone: '',
      level: '',
      levelName: '',
      showPopup: false,
      showRule: false,
      levelUrl: require('../../assets/imgs/level.png'),
      rechargeUrl: require('../../assets/imgs/recharge.png'),
      withdrawUrl: require('../../assets/imgs/withdraw.png'),
      warningUrl: require('../../assets/imgs/icon-warning.png'),
      vipUrl: require('../../assets/imgs/icon-vip2.png'),
      menuUrl: require('../../assets/imgs/mine-menu.png'),
      closeUrl: require('../../assets/imgs/icon-close.png'),
      copyUrl: require('../../assets/imgs/icon-copy.png'),
      linkUrl: require('../../assets/imgs/icon-link.png'),

      langListBak: [
         {
          name: 'English',
          key: 'en',
          icon: require('../../assets/imgs/lang/en.png'),
        },
          // 哈萨克斯坦语
        {
          name: 'Русский',
          key: 'kk',
          icon: require('../../assets/imgs/lang/kk.png'),
        },
        //   西班牙语
        {
          name: 'Español',
          key: 'es',
          icon: require('../../assets/imgs/lang/es.png'),
        },
          // 法语
        {
          name: 'Français',
          key: 'fr',
          icon: require('../../assets/imgs/lang/fr.png'),
        },
          // 德语
        {
          name: 'Deutsch',
          key: 'de',
          icon: require('../../assets/imgs/lang/de.png'),
        },
          // 意大利语
        {
          name: 'Italiano',
          key: 'it',
          icon: require('../../assets/imgs/lang/it.png'),
        },
          // 葡萄牙语
        {
          name: 'Português',
          key: 'pt',
          icon: require('../../assets/imgs/lang/pt.png'),
        },
          // 俄语
        {
          name: 'Pусский',
          key: 'ru',
          icon: require('../../assets/imgs/lang/ru.png'),
        },
        //   土耳其语
        {
          name: 'Türkçe',
          key: 'tr',
          icon: require('../../assets/imgs/lang/tr.png'),
        },
          // 韩语
        {
          name: '한국어',
          key: 'ko',
          icon: require('../../assets/imgs/lang/ko.png'),
        },
          // 日语
        {
          name: '日本語',
          key: 'ja',
          icon: require('../../assets/imgs/lang/ja.png'),
        },
          // 越南语
        {
          name: 'Tiếng Việt',
          key: 'vi',
          icon: require('../../assets/imgs/lang/vi.png'),
        },
          // 印尼语
        {
          name: 'Bahasa Indonesia',
          key: 'id',
          icon: require('../../assets/imgs/lang/id.png'),
        },
          // 马来语
        {
          name: 'Bahasa Melayu',
          key: 'ms',
          icon: require('../../assets/imgs/lang/ms.jpeg'),
        },
          // 泰语
        {
          name: 'ภาษาไทย',
          key: 'th',
          icon: require('../../assets/imgs/lang/th.png'),
        },
          // 阿拉伯语
        {
          name: 'العربية',
          key: 'ar',
          icon: require('../../assets/imgs/lang/ar.png'),
        },
          // 希伯来语
        {
          name: 'עברית',
          key: 'he',
          icon: require('../../assets/imgs/lang/he.png'),
        },
          // 印地语
        {
          name: 'हिन्दी',
          key: 'hi',
          icon: require('../../assets/imgs/lang/hi.png'),
        },
        {
          name: '简体中文',
          key: 'zh-CN',
          icon: require('../../assets/imgs/lang/zh.png'),
        },
        {
          name: '繁体中文',
          key: 'zh-TW',
          icon: require('../../assets/imgs/lang/zh.png'),
        },
      ],
      langList: [],
      langName: '',
      lang: 'en',
      langIcon: require('../../assets/imgs/lang/zh.png'),
      showLang: false,
      shareCode: ''
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    },
    avatar() {
      return this.$store.state.avatar
    },
    withdraw_bind_address() {
        return this.$store.state.withdraw_bind_address
    },
    withdraw_bind_bank() {
      return this.$store.state.withdraw_bind_bank
    },
    language() {
      return this.$store.state.language
    },
    shareLink() {
      return this.$store.state.appPromotion
    },
    menuList() {
      let arr = [
        {
          text: this.i18n.t('mine.menu1'),
          path: 'address'
        },
        {
          text: this.i18n.t('mine.menu2'),
          path: 'share'
        },
        {
          text: this.i18n.t('mine.menu3'),
          path: 'order'
        },
        {
          text: this.i18n.t('mine.menu4'),
          path: 'record'
        },
        {
          text: this.i18n.t('mine.menu5'),
          path: 'team'
        },
        {
          text: this.i18n.t('mine.menu6'),
          path: 'company'
        },
        // {
        //   text: this.i18n.t('mine.menu8'),
        //   path: 'bankList'
        // },
        // {
        //   text: this.i18n.t('mine.menu7'),
        //   path: 'usdtList'
        // },
      ]

      return arr
    }
  },
  watch: {
    language(val) {
      this.initLang()
    }
  },
  mounted() {

    this.shareCode = window.localStorage.getItem("shareCode")
    this.init()
    if (this.language) {
      this.initLang()
    }
    this.langList = [].concat(this.langListBak)
  },
  methods: {
    initLang() {
      this.langList = this.langListBak.filter(v => {
        return this.language.indexOf(v.key) > -1
      })
      this.lang = localStorage.getItem('locale') || 'en'
      let index = this.langList.findIndex(v => {
        return this.lang == v.key
      })
      if (index > -1) {
        this.langIcon = this.langList[index].icon
      }
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    init() {
      userInfo().then(res => {
        this.$store.state.avatar = res.data.Avatar || 1
        this.nickName = res.data.Nickname
        this.userName = res.data.UserName
        this.userMoney = res.data.UserAccount.Balance -res.data.UserAccount.Freeze
        this.levelName = res.data.Leave.Leave
      })
    },

    toPage(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    toLang() {
      this.showLang = !this.showLang
    },
    changeLang(data) {
      this.showLang = false
      this.i18n.locale = data.key
      localStorage.setItem('locale', data.key)
      this.langIcon = data.icon
      this.lang = data.key
    },
    logout() {
      removeToken()
      this.$router.push({
        name: 'login'
      })
    },
    cancel() {
      this.showPopup = false
      this.showRule = false
    },
    copyData(type) {
      let data = type == 1 ? this.shareCode : this.shareLink
      const clipboard = new Clipboard('.copy-icon', {text: () => data});
      this.$toast.success('复制成功')
    },
    getLevelName() {
      let name = ''
      switch(this.levelName + '') {
        case '1': name = this.$t('common.level1'); break;
        case '2': name = this.$t('common.level2'); break;
        case '3': name = this.$t('common.level3'); break;
        case '4': name = this.$t('common.level4'); break;
        case '5': name = this.$t('common.level5'); break;
        case '6': name = this.$t('common.level6'); break;
        default: name = this.$t('common.level1'); break
      }
      return name
    }
  }
}
</script>
<style lang="less">

</style>
